
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { FileTextOutlined } from "@ant-design/icons-vue";
import useDealerRoundSummary from "@/hooks/payment/controlling/Dealer/DealerPaymentOverview/DealerRoundSummary/useDealerRoundSummary";
import EntityWrapper from "@/views/Payment/Mgmt/components/entityWrapper.vue";
import Entity from "./entity.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
export default defineComponent({
  name: "paymentTracking",
  components: {
    FileTextOutlined,
    EntityWrapper,
    Entity,
    EntitySelector,
  },
  setup() {
    const route = useRoute();

    const {
      payoutRound,
      pageData,
      versions,
      generateSelectedEntity,
      downloadSelectedEntity,
      downloadSelectedVersion,
      generatePayoutSummary,
      downloadPayoutSummary,
      isHasMBCL,
      isInRound,
      isApproved,
      handleDownloadPaymentOverview
    } = useDealerRoundSummary();
    payoutRound.value = route.params.payoutRound as string;

    const selectEntityDialogVisiable = ref(false);

    const handleShowSelectEntityDialog = () => {
      if (isHasMBCL.value && isInRound.value) {
        generateSelectedEntity.value = "MBCL";
        selectEntityDialogVisiable.value = true;
      }
    };

    const selectEntityVersionDialogVisiable = ref(false);

    const handleShowSelectVersionEntityDialog = () => {
      if (isHasMBCL.value && isInRound.value) {
        downloadSelectedEntity.value = "MBCL";
        selectEntityVersionDialogVisiable.value = true;
      }
    };

    const handleGeneratePayoutSummary = () => {
      generatePayoutSummary();
      selectEntityDialogVisiable.value = false;
    };

    const handleDownloadPayoutSummary = () => {
      downloadPayoutSummary();
      selectEntityVersionDialogVisiable.value = false;
    };

    return {
      pageData,
      payoutRound,
      selectEntityDialogVisiable,
      handleShowSelectEntityDialog,
      selectEntityVersionDialogVisiable,
      handleShowSelectVersionEntityDialog,
      versions,
      generateSelectedEntity,
      downloadSelectedEntity,
      downloadSelectedVersion,
      handleGeneratePayoutSummary,
      handleDownloadPayoutSummary,
      isHasMBCL,
      isInRound,
      isApproved,
      handleDownloadPaymentOverview
    };
  },
});
